<template>
  <div id="CompanyDetail">
    <div class="titleTop posFix" >
      <!-- <div @click="changePath('/FindOut')">问答</div> -->
      <div @click="changePath('/Connections')">人脉圈</div>
      <div class="active">公司库</div>
      <div @click="changePath('/ActivityLine?activeName=c')">线下活动</div>
      <div @click="changePath('/ActivityLine?activeName=d')">保观集市</div>
    </div>
    <div class="list">
      <img :src="Global.changeImgCom(companyMess.thumb)" />
      <div v-if="companyMess">
        <p class="second">{{companyMess.title}}</p>
        <p class="first" style="margin-bottom:0.07rem">{{company_info.company_name}}</p>
        <p class="forth">
          <van-icon v-if="company_info.place" color="#727387"  name="location" style="margin:0 0.05rem 0 0;font-size:0.12rem"/>
          <span>{{company_info.place}}</span>
        </p>
      </div>
    </div>

    <div class="bottom" v-if="companyMess">
      <van-tabs @click="onClick">
        <van-tab title="公司概况">
          <div class="personalInfo">
            <div >
              <p class="second">公司简介</p>
              <div class="third">
                <p style="margin-bottom:0.1rem"><a :href="company_info.company_url">{{company_info.company_url}}</a></p>
                <p><span>{{companyMess.description}}</span></p>
              </div>
            </div>
            <div >
              <p class="second">融资情况</p>
              <p class="third" v-if="company_info.new_financing" v-html="company_info.new_financing"></p>
              <p class="third" v-if="company_info.rongzi" v-html="company_info.rongzi"></p>
            </div> 
            <!-- <div >
               <p class="second">融资情况</p> 
              <div class="steps">
                <div>
                  <div>最新轮次</div>
                  <div><span></span></div>
                  <div>{{company_info.new_financing}}</div>
                </div> 
                 <div>
                  <div>{{companyMess.financing}}</div>
                  <div><span></span></div>
                  <div v-html="company_info.rongzi"></div>
                </div>
                
              </div>
            </div> -->
            <div >
              <p class="second">新闻</p>
              <div class="news" v-html="company_info.companynews">
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="工商信息">
          <div class="personalInfo">
            <div>
              <p class="second">工商基本信息</p>
              <!-- <p class="third" v-html="company_info.industry_commerce"></p> -->
              <div class="steps color">
                <p>公司全称：{{company_info.company_name}}</p>
                <p>法定代表人：{{companyMess.product_founder}}</p>
                <p>注册时间：{{companyMess.establish}}</p>
                <p>注册资本：{{company_info.registered_capital}}</p>
                <p>联系电话：{{company_info.company_telphone}}</p>
                <p>通讯邮箱：{{company_info.company_email}}</p>
              </div>
            </div>
            <div v-if="company_info.shareholder">
              <p class="second">股东情况</p>
              <p class="third" v-html="company_info.shareholder"></p>
            </div> 
          </div>
        </van-tab>
        <van-tab title="团队信息">
          <div class="personalInfo" id="aboutMessage">
            <div v-if="company_info.board_directors">
              <p class="second">董高监</p>
              <p class="third" v-html="company_info.board_directors"></p>
            </div> 
            <div v-if="company_info.team">
              <p class="second">主要成员</p>
              <p class="third" v-html="company_info.team"></p>
            </div> 
           
            <!-- <div >
              <p class="second">创新点</p>
              <p class="first">CEO</p>
              <p class="third">11月25日保监会公布2020年上半年保险业绩数据报告显示为行业原保费 增长23%，同比增长12%，净利润715.1亿元，同比增比增长12%，净利润715.1亿元，同比增产</p>
            </div> -->
          </div>
        </van-tab>
        <van-tab title="产品与服务">
          <div class="personalInfo" >
            <div v-if="company_info.company_app">
              <p class="second">APP</p>
              <p class="third" v-html="company_info.company_app"></p>
            </div> 
            <div v-if="company_info.official_accounts">
              <p class="second">公众号</p>
              <p class="third" v-html="company_info.official_accounts"></p>
            </div> 
            <div v-if="company_info.team">
              <p class="second">微博</p>
              <p class="third" v-html="company_info.weibo"></p>
            </div> 
            <!-- <div v-if="company_info.team">
              <p class="second">产品</p>
              <p class="third" v-html="company_info.team"></p>
            </div>  -->
          </div>  
        </van-tab>
      </van-tabs>
    </div>
    
  </div>
</template>
<script>
export default {
  name: 'CompanyDetail',
   data(){
    return{
      id:'',
      companyMess:'',
      company_info:''
    }
  },
  mounted(){
    this.id=this.$route.query.id
    this.axios({
        method: 'GET',
        url:'company/getCompanyInfo?companyId='+this.id,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.companyMess = res.data.data
          this.company_info = res.data.data.company_info
        }
      })
  },
  methods:{
    changePath(path){
      this.path = path
      this.$router.push(path)
    },
    onClick(name, title) {
      console.log(name,title)
    },
   
  }
}
</script>
<style>
.news a,.news p{
  color: #989AB1;
  display: block;
  text-decoration:underline;
}
.productMessage img{
  width: 100%;
}
#aboutMessage  p,.steps{
  font-size: 0.12rem;
  line-height: 0.17rem;
  padding: 0.15rem 0 0.1rem;
  color: #989AB1;
}
</style>
<style lang="less" scoped>
#CompanyDetail{
  .posFix{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background: #16171D;
  }
  .titleTop {
    padding: 0.15rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    border-bottom: .1px solid #5C5D6E61;
    color: #989ab1;
    background: #23252E;
    > div {
      margin-right: 0.2rem;
      position: relative;
    }
    .active {
      font-weight: 600;
      color: #f6f7fd;
      font-size: 0.14rem;
    }
    .active::after {
      position: absolute;
      content: "";
      height: 2px;
      background: #3890ff;
      left: 0;
      margin: auto 0.1rem;
      right: 0;
      bottom: -0.05rem;
    }
  }
  .productMessage{
    color: #989AB1;
    font-size: 0.12rem;
    line-height: 0.17rem;
    padding: 0.3rem 0.15rem 0.2rem;
    background: #23252E;
  }
  #aboutMessage{
    padding: 0.15rem 0.15rem 0;
    background: #23252E;
    >div{
      padding: 0.15rem 0 0.1rem;
      border-bottom: .1px solid #5C5D6E61;
      .first{
        font-size: 0.12rem;
        margin: 0.05rem 0 0;
        color: #989AB1;
      }
      .third{
        padding-top: 0.1rem;
      }
    }
    >div:last-child{
      border: none;
    }
  }
  .news{
    padding-top: 0.15rem;
  }
  .list{
    display: flex;
    padding: 0.5rem 0.15rem 0;
    align-items: center;
    background: #23252E;
    >img{
      width: 0.55rem;
      border-radius: 5px;
      height: 0.55rem;
    }
    >div{
      padding: 0.15rem;
      position: relative;
      flex: 1;
      .first{
        font-size: 0.14rem;
        color: #F6F7FD;
      }
      .third{
        display: flex;
        flex-wrap: wrap;
        >span{
          padding: 0 0.05rem;
          color: #75B2FF;
          font-size: 0.12rem;
          border-radius: 0.02rem;
          background: #2862AC;
          margin: 0 0.05rem 0.05rem 0;
        }
      }
      .forth{
        color: #727387;
        font-size: 0.12rem;
        margin-bottom: 0.06rem;
        display: flex;
        align-items: center;
      }
      .second{
        margin: 0.03rem 0 0.07rem 0;
        color: #989AB1;
        font-size: 0.21rem;
        display: flex;
        font-size: 0.13rem;
        >span{
          display: flex;
          margin-left: 0.1rem;
          color: #989AB1;
        }
      }
    }
  }

  .titleTab{
    display: flex;
    flex-wrap: wrap;
    padding: 0.15rem 0;
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-bottom: 0.1rem;
      margin-right: 0.12rem;
    }
  }
  .personalInfo .color>div{
    color: #ECEDF7;
    .gray{
      color: #989AB1;
    }
    >div:nth-child(2){
      span{
        background: #3890FF;
        position: relative;
        z-index: 10;
      }
    }
    >div:last-child{
      p{
        margin-bottom: 0.05rem;
        line-height: 0.17rem;
      }
    }
  }
  .steps{
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    >div{
      display: flex;
      padding-left: 0.2rem;
      color: #989AB1;
      font-size: 0.12rem;
      min-height: 0.45rem;
      >div:first-child{
        width: 0.53rem;
      }
      >div:nth-child(2){
        position: relative;
        >span{
          width: 6px;
          height: 6px;
          background: #727387;
          border-radius: 50%;
          display: block;
          margin: 0 0.12rem 0 0.12rem;
        }
      }
      >div:nth-child(2)::after{
        content: '';
        position:absolute;
        height: 100%;
        bottom: 0;
        width: 1px;
        left: 0;
        right: 0;
        margin: auto;
        background: #727387;
        line-height: 0.17rem;

      }
      >div:last-child{
        flex: 1;
      }
    }
  }
  .personalInfo{
    >div:last-child{
      margin-bottom: 0;
    }
    >div:first-child{
      padding-top: 0.3rem;
    }
    >div{
      padding: 0.15rem 0.15rem 0.1rem;
      margin-bottom: 0.11rem;
      background: #23252E;
      .third{
        color: #989AB1;
        font-size: 0.12rem;
        line-height: 0.17rem;
        padding: 0.15rem 0 0.1rem;
        a{
          color: #989AB1;
          font-size: 0.12rem;
          line-height: 0.17rem;
          text-decoration: underline;
        }
      }
      .second{
        color: #F6F7FD;
        font-size: 0.14rem;
      }
    }
  }
  .bottom{
    margin-top: 0.1rem;
  }
  
  .titleTop {
    padding: 0.15rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    border-bottom: .1px solid #5C5D6E61;
    color: #989ab1;
    > div {
      margin-right: 0.2rem;
      position: relative;
    }
    .active {
      font-weight: 600;
      color: #f6f7fd;
    }
    .active::after {
      position: absolute;
      content: "";
      width: 0.2rem;
      height: 2px;
      background: #3890ff;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -0.05rem;
    }
  }
}
</style>